<template>
  <tr class="MediaChannelManagerRow">
    <td style="width: 10%">
      <form-field
        :validator="$v.form.type"
        attribute="Channel Type"
      >
        <basic-select
          v-model="form.type"
          :values="availableMediaTypes"
          :disabled="!isNew"
          value-prop="key"
          key-prop="key"
          label-prop="label"
        >
          <span slot-scope="{ item }">{{ $t(item.label) }}</span>
        </basic-select>
      </form-field>
    </td>
    <td>
      <form-field
        :validator="$v.form.identifier"
        attribute="Identifier"
      >
        <input
          v-model="form.identifier"
          class="input"
          type="text"
          name="channel_identifier"
          placeholder="Channel Identifier"
          @input="$v.form.identifier.$touch()"
        >
      </form-field>
    </td>
    <td>
      <form-field
        :validator="$v.form.impressions"
        attribute="Impressions"
      >
        <input
          v-model.number="form.impressions"
          class="input"
          type="text"
          name="channel_impressions"
          placeholder="Channel Impressions"
          @input="$v.form.impressions.$touch()"
        >
      </form-field>
    </td>
    <td class="is-narrow has-text-centered">
      <v-button
        class="is-plain is-dark"
        @click="cancelEdit"
      >
        Cancel
      </v-button>
      <v-button
        :loading="form.isLoading"
        :class="{'is-success': !isNew }"
        class="is-dark m-b-none"
        @click="saveMediaChannel"
      >
        {{ isNew ? 'Add': 'Save' }}
      </v-button>
    </td>
  </tr>
</template>

<script>
import Form from '@/services/forms/Form'
import { MEDIA_TYPES_ARRAY } from '@hypefactors/shared/js/constants/mediaTypes'
import { required, numeric } from 'vuelidate/lib/validators'

export default {
  props: {
    mediaOutletId: {
      type: [Number, String],
      required: true
    },
    mediaChannel: {
      type: Object,
      default: () => ({
        identifier: '',
        type: '',
        impressions: ''
      })
    },
    unavailableChannelTypes: {
      type: Array,
      default: () => ([])
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  data () {
    return {
      form: new Form({
        identifier: { value: '', rules: { required } },
        type: { value: '', rules: { required } },
        impressions: { value: 0, rules: { numeric, required } }
      })
    }
  },

  computed: {
    mediaTypes () {
      return MEDIA_TYPES_ARRAY
    },
    availableMediaTypes () {
      return this.mediaTypes.filter(type => !this.unavailableChannelTypes.includes(type.key))
    },
    isNew () {
      return !this.mediaChannel.id
    }
  },

  mounted () {
    this.form.merge(this.mediaChannel)
  },

  methods: {
    saveMediaChannel () {
      this.$v.$touch()
      if (this.$v.form.$error) return
      this.form.setPayloadTransformer((payload) => {
        payload.media_outlet_id = this.mediaOutletId
        return payload
      })
      const action = this.isNew ? this.createMediaChannel : this.updateMediaChannel
      return action()
        .then((response) => {
          this.form.reset()
          this.$v.form.$reset()
          this.$emit('update', response.data)
        })
    },

    createMediaChannel () {
      return this.form.post(`/staff/media-outlets/${this.mediaOutletId}/channels`)
    },

    updateMediaChannel () {
      return this.form.put(`/staff/media-outlets/${this.mediaOutletId}/channels/${this.mediaChannel.id}`)
    },

    cancelEdit () {
      this.form.reset()
      this.$v.form.$reset()
      this.$emit('cancel')
    }
  }
}
</script>

<style
  rel='stylesheet/scss'
  lang='scss'
>
@import '~utils';

</style>
