import { render, staticRenderFns } from "./MediaChannelManagerRow.vue?vue&type=template&id=2e8b5075&"
import script from "./MediaChannelManagerRow.vue?vue&type=script&lang=js&"
export * from "./MediaChannelManagerRow.vue?vue&type=script&lang=js&"
import style0 from "./MediaChannelManagerRow.vue?vue&type=style&index=0&rel=stylesheet%2Fscss&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports