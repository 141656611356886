<template>
  <div v-loading="isFetchingChannels" class="MediaChannelManager">
    <table class="table is-fullwidth has-cells-vertically-centered">
      <thead>
        <tr>
          <th>Type</th>
          <th>Identifier</th>
          <th>Impressions</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <media-channel-manager-row-editable
          key="newMediaChannel"
          :media-outlet-id="mediaOutletId"
          :unavailable-channel-types="unavailableChannelTypes"
          @update="fetchMediaChannels"
        />
        <media-channel-manager-row
          v-for="mediaChannel in mediaChannels"
          :key="mediaChannel.id"
          :media-channel="mediaChannel"
          :media-outlet-id="mediaOutletId"
          :has-associated-clippings="hasAssociatedClippings"
          @update="fetchMediaChannels"
          @deleted="fetchMediaChannels"
        />
      </tbody>
    </table>
  </div>
</template>

<script>

import MediaChannelManagerRow from '@/components/staff/MediaChannelManagerRow'
import MediaChannelManagerRowEditable from '@/components/staff/MediaChannelManagerRowEditable'

export default {
  components: { MediaChannelManagerRow, MediaChannelManagerRowEditable },
  props: {
    mediaOutletId: {
      type: [Number, String],
      required: true
    },
    hasAssociatedClippings: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isFetchingChannels: false,
      mediaChannels: []
    }
  },
  computed: {
    unavailableChannelTypes () {
      return this.mediaChannels.map(channel => channel.type)
    }
  },
  mounted () {
    this.fetchMediaChannels()
  },
  methods: {
    fetchMediaChannels () {
      this.isFetchingChannels = true
      return this.$api.get(`/staff/media-outlets/${this.mediaOutletId}/channels`)
        .then(response => {
          this.isFetchingChannels = false
          this.mediaChannels = response.data.data
        })
        .catch((err) => {
          this.isFetchingChannels = false
          this.$displayRequestError(err)
        })
    }
  }
}
</script>
