<template>
  <div class="MediaOutletManagement">
    <heading size="3">
      Media Outlet
    </heading>
    <form
      v-loading="isFetchingMediaOutlet || form.isLoading"
      class="m-b-l"
      @submit.prevent="storeMediaOutlet"
    >
      <div class="columns">
        <div class="column">
          <form-field :validator="$v.form.name" label="Media Outlet Name">
            <input
              v-model="form.name"
              class="input"
              type="text"
              name="media_outlet_name"
              placeholder="Media Outlet Name"
              @input="$v.form.name.$touch()"
            >
          </form-field>
        </div>
        <div class="column">
          <form-field :validator="$v.form.publisher" label="Publisher">
            <input
              v-model="form.publisher"
              class="input"
              type="text"
              name="publisher"
              placeholder="Publisher"
              @input="$v.form.publisher.$touch()"
            >
          </form-field>
        </div>
        <div class="column">
          <form-field :validator="$v.form.is_person_or_celebrity" label="Is person or celebrity">
            <el-switch
              v-model="form.is_person_or_celebrity"
              :disabled="!isNewMediaOutlet"
              active-text="Celebrity"
              inactive-text="Person"
            />
          </form-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <form-field :validator="$v.form.country" label="Country">
            <country-picker
              v-model="form.country"
              value-key="iso_alpha2"
            />
          </form-field>
        </div>
        <div class="column">
          <form-field :validator="$v.form.language" label="Language">
            <language-picker
              v-model="form.language"
              value-key="id"
              :disabled="hasLanguage"
            />
          </form-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <form-field :validator="$v.form.broad_narrow" label="Broad Narrow">
            <basic-select
              v-model="form.broad_narrow"
              :values="broadNarrow"
              label-prop="label"
              value-prop="key"
              key-prop="key"
              clearable
            />
          </form-field>
        </div>
        <div class="column">
          <form-field :validator="$v.form.geography" label="Geography">
            <basic-select
              v-model="form.geography"
              :values="geography"
              label-prop="label"
              value-prop="key"
              key-prop="key"
              clearable
            />
          </form-field>
        </div>
        <div class="column">
          <form-field :validator="$v.form.publication_interval" label="Publication Interval">
            <basic-select
              v-model="form.publication_interval"
              :values="publication_interval"
              label-prop="label"
              value-prop="key"
              key-prop="key"
              clearable
            />
          </form-field>
        </div>
      </div>
      <div class="has-text-right">
        <restricted-button
          :disabled="hasAssociatedClippings || isNewMediaOutlet"
          :loading="isDeleting"
          permission
          tooltip-text="Cannot delete Media Outlet as it has clippings associated to it."
          action-class="button is-plain is-danger"
          @click="deleteMediaOutlet"
        >
          Delete
        </restricted-button>

        <v-button
          :loading="form.isLoading"
          class="is-primary"
          type="submit"
        >
          Save
        </v-button>
      </div>
    </form>
    <div v-if="mediaOutletId" class="MediaOutletManagementChannels">
      <heading size="3">
        Media Outlet Channels
      </heading>
      <media-channel-manager :media-outlet-id="mediaOutletId" :has-associated-clippings="hasAssociatedClippings" />
    </div>
  </div>
</template>

<script>
import MediaChannelManager from '@/components/staff/MediaChannelManager'
import Form from '@/services/forms/Form'
import { required } from 'vuelidate/lib/validators'
import { MEDIA_BROAD_NARROW, MEDIA_GEORGRAPHY, MEDIA_PUBLICATION_INTERVAL } from '@hypefactors/shared/js/constants/mediaOutletCategories'

const createForm = () => new Form({
  name: { value: '', rules: { required } },
  publisher: { value: '', rules: { } },
  is_person_or_celebrity: { value: false },
  country: { value: null, rules: { required } },
  language: { value: null, rules: { required } },
  broad_narrow: { value: null, rules: { required } },
  geography: { value: null },
  publication_interval: { value: null },
  active_from: { value: null },
  active_to: { value: null }
})

export default {
  components: {
    MediaChannelManager
  },

  props: {
    mediaOutletId: {
      type: [Number, String],
      default: 0
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  data () {
    return {
      form: createForm(),
      isDeleting: false,
      mediaOutlet: null,
      isFetchingMediaOutlet: false
    }
  },

  computed: {
    isNewMediaOutlet () {
      return !this.mediaOutletId
    },
    broadNarrow () {
      return Object.entries(MEDIA_BROAD_NARROW()).map(([key, label]) => ({ key, label: this.$t(label) }))
    },
    geography () {
      return Object.entries(MEDIA_GEORGRAPHY()).map(([key, label]) => ({ key, label: this.$t(label) }))
    },
    publication_interval () {
      return Object.entries(MEDIA_PUBLICATION_INTERVAL()).map(([key, label]) => ({ key, label: this.$t(label) }))
    },
    hasAssociatedClippings () {
      return this.mediaOutlet ? this.mediaOutlet.clippings_count > 0 : false
    },
    hasLanguage () {
      return this.mediaOutlet && !Array.isArray(this.mediaOutlet.language.data)
    }
  },

  mounted () {
    if (!this.isNewMediaOutlet) {
      this.fetchMediaOutlet()
    }
  },

  beforeDestroy () {
    this.form.reset()
  },

  methods: {
    async fetchMediaOutlet () {
      this.isFetchingMediaOutlet = true
      try {
        this.$api.get(`/media-outlets/${this.mediaOutletId}?include=country,language`)
          .then(response => {
            this.mediaOutlet = response.data.data
            this.form.merge(this.mediaOutlet)

            if (this.mediaOutlet.country.data && !Array.isArray(this.mediaOutlet.country.data)) {
              this.form.country = this.mediaOutlet.country.data
            }

            if (this.mediaOutlet.language.data && !Array.isArray(this.mediaOutlet.language.data)) {
              this.form.language = this.mediaOutlet.language.data
            }
          })
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isFetchingMediaOutlet = false
      }
    },

    async storeMediaOutlet () {
      this.$v.$touch()

      if (this.$v.$error) return

      this.form.setPayloadTransformer((data) => {
        const payload = {
          ...data,
          brand: this.activeBrandId
        }

        if (payload.country) {
          payload.country = payload.country.id
        }

        if (payload.language) {
          payload.language = payload.language.id
        }

        return payload
      })

      let action = this.isNewMediaOutlet ? this.createMediaOutlet : this.updateMediaOutlet

      return await action()
        .catch((err) => {
          this.$displayRequestError(err)
        })
    },

    createMediaOutlet () {
      return this.form.post('/staff/media-outlets')
        .then((response) => {
          this.$router.push({ name: 'staff.media-outlets.edit', params: { mediaOutletId: response.data.id } })

          this.$notify.success({
            title: this.$t('general.success'),
            message: 'Media Outlet was successfully created' // TODO: Translate it later
          })
        })
    },

    updateMediaOutlet () {
      return this.form.put(`/staff/media-outlets/${this.mediaOutletId}`)
        .then(async () => {
          await this.fetchMediaOutlet()

          this.$notify.success({
            title: this.$t('general.success'),
            message: 'Media Outlet was successfully updated' // TODO: Translate it later
          })
        })
    },

    async deleteMediaOutlet () {
      try {
        this.isDeleting = true
        await this.$confirm('Are you sure you want to delete this outlet?')
        await this.form.delete(`/staff/media-outlets/${this.mediaOutletId}`)
        this.$router.push({ name: 'staff.media-outlets' })

        this.$notify.success({
          title: this.$t('general.success'),
          message: 'Media Outlet was successfully deleted' // TODO: Translate it later
        })
      } catch (err) {
        if (err === 'cancel') return
        this.$displayRequestError(err)
      } finally {
        this.isDeleting = false
      }
    }
  }
}
</script>
