<template>
  <tr
    v-if="!isEdited"
    class="MediaChannelManagerRow"
  >
    <td>
      {{ $t(mediaTypes[mediaChannel.type].label) }}
    </td>
    <td>
      {{ mediaChannel.identifier }}
    </td>
    <td>
      {{ mediaChannel.impressions }}
    </td>
    <td class="has-text-centered">
      <restricted-button
        :loading="isDeleting"
        :disabled="mediaChannel.clippings_count > 0"
        permission
        tooltip-text="Cannot delete media channel as it has connected clippings to it."
        action-class="button is-plain is-danger"
        @click="deleteChannel"
      >
        <i class="hf hf-trash" />
      </restricted-button>
      <v-button
        class="is-primary"
        @click="isEdited = true"
      >
        <i class="hf hf-pencil" />
      </v-button>
    </td>
  </tr>
  <media-channel-manager-row-editable
    v-else
    :key="mediaChannel.id"
    :media-outlet-id="mediaOutletId"
    :media-channel="mediaChannel"
    @update="finishUpdate"
    @cancel="handleCancel"
  />
</template>

<script>
import MediaChannelManagerRowEditable from '@/components/staff/MediaChannelManagerRowEditable'
import { MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'

export default {
  components: { MediaChannelManagerRowEditable },

  props: {
    mediaChannel: {
      type: Object,
      default: () => ({
        identifier: '',
        type: '',
        impressions: ''
      })
    },
    mediaOutletId: {
      type: [Number, String],
      required: true
    },
    hasAssociatedClippings: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      isEdited: false,
      isDeleting: false
    }
  },

  computed: {
    mediaTypes () {
      return MEDIA_TYPES
    }
  },

  methods: {
    finishUpdate () {
      this.isEdited = false
      this.$emit('update')
    },

    handleCancel () {
      this.isEdited = false
    },

    async deleteChannel () {
      try {
        await this.$confirm('Are you sure you want to delete this media channel?')
        this.isDeleting = true
        await this.$api.delete(`/staff/media-outlets/${this.mediaOutletId}/channels/${this.mediaChannel.id}`)
        this.$emit('deleted')
      } catch (err) {
        if (err === 'cancel') return
        this.$displayRequestError(err)
      } finally {
        this.isDeleting = false
      }
    }
  }
}
</script>

<style
  rel='stylesheet/scss'
  lang='scss'
>
@import '~utils';

</style>
